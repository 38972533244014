import { dataMethods, resultsComputed, resultsMethods } from '@state/helpers.js';
import { appName } from '../version.js';
import template from './init-app.html';

export default {
  template,
  data() {
    return { appName };
  },
  computed: {
    ...resultsComputed,
  },
  methods: {
    ...resultsMethods,
    ...dataMethods,
  },
  async mounted() {
    this.syncCount = 4; // bitwise flags

    this.unsubscribe = this.$store.subscribeAction({
      after: async ({ type }, state) => {
        switch (type) {
          // case 'project/fetchProject':
          //   // all done so unsubscribe from other notifications

          //   // await this.$store.dispatch('profile/fetchProfile');
          //   this.haveProject = true;
          //   break;
          case 'data/setStatusUp':
            {
              if (!this.haveData) {
                // console.log('status up');
                const current = this.currentResults;
                if (current && current.state !== 'aborted') {
                  if (current.state !== 'prestart' && current.state !== 'pending') {
                    this.setState('abandoned');
                  }
                  await this.save({ token: 'wiffle', value: current });
                  await this.clearResults();
                }
                this.syncCount &= ~4; // clear the 4bit
              }
            }
            break;
        }
        if (this.syncCount == 0) {
          this.unsubscribe();
          this.$emit('init-app', { type: 'fetchProject' });
        }
      },
    });
  },
};

export class Buffer {
  constructor(context, urls) {
    this.context = context;
    if (!Array.isArray(urls)) {
      urls = [urls];
    }
    this.urls = urls;
    this.buffer = [];
    this.loaded = false;

    return this;
  }

  async loadSound(url, index) {
    const isBlob = url instanceof Blob;

    const buffer = await (isBlob ? blobToArrayBuffer(url) : fetchUrl(url)); //  await response.arrayBuffer();
    this.buffer[index] = await this.context.decodeAudioData(buffer);
  }

  async loadAll() {
    return Promise.all(this.urls.map((url, index) => this.loadSound(url, index))).then(() => {
      this.loaded = true;
    });
  }

  getSounds() {
    return this.buffer;
  }
  getSoundByIndex(index) {
    return this.buffer[index];
  }
}

// ===
// Private functions
// ===

async function blobToArrayBuffer(blob) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onloadend = () => {
      resolve(fileReader.result);
    };

    fileReader.onerror = err => reject(err);
    fileReader.readAsArrayBuffer(blob);
  });
}

async function fetchUrl(url) {
  const response = await fetch(url, {
    method: 'GET',
    headers: new Headers({
      'Content-Type': 'audio/*',
    }),
  });

  return await response.arrayBuffer();
}

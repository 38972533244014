import { Buffer } from './buffer.js';
import { SoundBase } from './sound-base.js';

const playResolve_ = Symbol();

export class Sound extends SoundBase {
  static create(context, srcs, muted) {
    // Automatically try to enable audio on iOS.
    if (context.mobileAutoEnable && context.enableMobileAudio) {
      context.enableMobileAudio();
    }

    return new Sound({ context, srcs, muted });
  }

  constructor({ context, srcs, muted = false }) {
    super({ context, muted });

    this.buffer = new Buffer(context, srcs);
    return this;
  }

  refreshNodes() {
    this.sources = this.buffer.getSounds().map(b => {
      const source = this.context.createBufferSource();
      source.buffer = b;
      source.connect(this.sourceDestination);

      source.onended = e => {
        this.cleanBuffer(source);
        if (this.sources.length === 0) {
          this.isPlaying_ = false;
          if (this[playResolve_]) {
            this[playResolve_]();
          }
        }
      };
      return source;
    });
  }

  async play(when = this.context.currentTime) {
    if (this.isPlaying_) {
      return;
    }
    if (!this.buffer.loaded) {
      await this.load();
    }

    this.refreshNodes();
    this.isPlaying_ = true;

    this.gainNode.gain.setValueAtTime(this.muted ? 0 : this.volume, this.context.currentTime);

    return new Promise(resolve => {
      this[playResolve_] = resolve;
      let lastChunkOffset = 0;
      this.sources.forEach(s => {
        s.start(when + lastChunkOffset);
        lastChunkOffset += s.buffer.duration;
      });
    });
  }

  stop() {
    if (this.isPlaying_) {
      this.gainNode.gain.exponentialRampToValueAtTime(0.001, this.context.currentTime + 0.5);
      this.sources.forEach(source => {
        source.stop(this.context.currentTime + 0.5);
      });
    }
  }

  dispose() {
    this.stop();
  }
}

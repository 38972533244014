export class SoundBase {
  constructor({ context, muted = false }) {
    Object.assign(this, { context, muted });

    this.volume_ = 1;
    this.isPlaying_ = false;
    this.gainNode = this.context.createGain();
    this.gainNode.connect(this.context.destination);
    this.sourceDestination_ = this.gainNode;
  }

  get isPlaying() {
    return this.isPlaying_;
  }
  get volume() {
    return this.volume_;
  }

  set volume(vol) {
    if (vol >= 0 && vol <= 1) {
      this.volume_ = vol;
      this.muted = false;
      this.gainNode.gain.setValueAtTime(vol, this.context.currentTime);
    }
  }
  connect(destination) {
    this.gainNode.connect(destination);
  }
  mute(muted) {
    this.muted = muted;
    this.gainNode.gain.setValueAtTime(muted ? 0 : this.volume_, this.context.currentTime);
  }
  get destination() {
    return this.gainNode;
  }

  get sourceDestination() {
    return this.sourceDestination_;
  }
  set sourceDestination(destination) {
    this.sourceDestination_ = destination;
  }

  async load() {
    if (this.buffer && !this.buffer.loaded) {
      await this.buffer.loadAll();
    }
    return this;
  }
  cleanBuffer(source) {
    // some mobile ios need to use small scratch buffer to eliminate a memory leak
    const { scratchBuffer } = this.context;

    if (scratchBuffer) {
      try {
        source.buffer = scratchBuffer;
      } catch (e) {
        /* empty */
      }
    }

    source.onended = null;
    source.disconnect(0);
    if (this.sources && Array.isArray(this.sources)) {
      const idx = this.sources.findIndex(s => s === source);
      if (idx >= 0) {
        this.sources.splice(idx, 1);
      }
    }
  }
}

import store from '@state/store.js';
import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes.js';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes,
});

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
  // Check if auth is required on this route
  // (including nested routes).
  const authRequired = routeTo.matched.some(route => route.meta.authRequired);

  // If auth isn't required for the route, just continue.
  if (!authRequired) {
    return next();
  }

  // if here then auth is required
  const redirectToLogin = () => next({ name: 'login' });

  // if logged in then validate, else go to login
  if (store.getters['auth/loggedIn']) {
    const validUser = store.dispatch('auth/validate');
    // if still valid continue, else login
    validUser ? next() : redirectToLogin();
  } else {
    // need to login
    redirectToLogin();
  }
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // Check if session is required on this route
  // (including nested routes).
  const sessionRequired = routeTo.matched.some(route => route.meta.sessionRequired);

  // If session isn't required for the route, just continue.
  if (!sessionRequired) {
    return next();
  }

  store.getters['session/userref'] ? next() : next({ name: 'home' });
});

export default router;

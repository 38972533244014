import store from '@state/store.js';
import loader from '../loader.js';

export default [
  {
    path: '/login/reset-password',
    component: () => loader.load('login/reset.js'),
  },
  {
    path: '/login',
    name: 'login',
    component: () => loader.load('login/login.js'),
  },
  {
    path: '/login/sendreset',
    name: 'sendreset',
    component: () => loader.load('login/sendreset.js'),
  },
  {
    path: '/logout',
    name: 'logout',
    meta: {
      authRequired: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      store.dispatch('auth/logOut');
      const authRequiredOnPreviousRoute = routeFrom.matched.some(route => route.meta.authRequired || true);
      // Navigate back to previous page, or home as a fallback
      next(authRequiredOnPreviousRoute ? { name: 'login' } : { ...routeFrom });
    },
  },
  {
    path: '/details',
    name: 'mydetails',
    meta: {
      authRequired: true,
    },

    component: () => loader.load('general/my-details.js'),
  },
  {
    path: '/pwd',
    name: 'changepassword',
    meta: {
      authRequired: true,
    },

    component: () => loader.load('general/change-password.js'),
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      authRequired: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      if (myRole() != 'user') {
        return next({ name: 'guide' });
      }

      next();
    },
    component: () => loader.load('home/participant-home.js'),
  },
  {
    path: '/guide',
    name: 'guide',
    meta: {
      authRequired: true,
    },
    beforeEnter(routeTo, routeFrom, next) {
      if (myRole() == 'user') {
        return next({ name: 'home' });
      }
      next();
    },
    component: () => loader.load('home/guide-home.js'),
  },
  {
    path: '/session',
    name: 'session',
    meta: {
      authRequired: true,
      sessionRequired: true,
    },
    component: () => loader.load('session/session-root.js'),
  },
  {
    path: '/instructions/:userref/:projectId',
    name: 'instructions',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('instructions/instructions.js'),
  },
  {
    path: '/participant-instructions',
    name: 'participant-instructions',
    meta: {
      authRequired: true,
      sessionRequired: true,
    },
    component: () => loader.load('instructions/participant-instructions.js'),
  },
  {
    path: '/practice',
    name: 'practice',
    meta: {
      authRequired: true,
      sessionRequired: true,
    },
    component: () => loader.load('instructions/practice.js'),
  },
  {
    path: '/end-practice',
    name: 'end-practice',
    meta: {
      authRequired: true,
      sessionRequired: true,
    },
    component: () => loader.load('instructions/end-practice.js'),
  },
  {
    path: '/calibration',
    name: 'calibration',
    meta: {
      authRequired: true,
    },
    component: () => loader.load('calibration/calibration.js'),
  },
  {
    path: '/help',
    name: 'help',
    component: () => loader.load('help/help.js'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => loader.load('about/about.js'),
  },
  {
    path: '/:remainder*',
    beforeEnter(routeTo, routeFrom, next) {
      const name = myRole() !== 'admin' ? 'home' : 'guide';
      next({ name });
    },
  },
  {
    path: '/app/:remainder*',
    beforeEnter(routeTo, routeFrom, next) {
      const name = myRole() === 'user' ? 'home' : 'guide';
      next({ name });
    },
  },
];

// ===
// Private functions
// ===

function myRole() {
  return store.getters['auth/myRole'];
}

/* global webkitAudioContext*/
// ployfill promise variant of decodeAudioData for iOS
if (!window.AudioContext && window.webkitAudioContext) {
  const oldFunc = webkitAudioContext.prototype.decodeAudioData;
  webkitAudioContext.prototype.decodeAudioData = function(arraybuffer) {
    return new Promise((resolve, reject) => {
      oldFunc.call(this, arraybuffer, resolve, reject);
    });
  };
}
const handler = {
  get: function(target, prop, receiver) {
    const origMethod = target[prop];

    return origMethod
      ? Reflect.get(target, prop, receiver)
      : typeof target.context[prop] == 'function'
      ? function(...args) {
          return target.context[prop](...args);
        }
      : Reflect.get(target.context, prop);
  },
};

export class AudioGraph {
  constructor({ context = getAudioContext(), mobileAutoEnable = true, muted = false } = {}) {
    Object.assign(this, { context, mobileAutoEnable, muted });

    this.masterGain = createMasterGain_(context, muted);

    this.codecs_ = getCodecs();
    this.volume_ = 1;
    this.navigator_ = typeof window !== 'undefined' && window.navigator ? window.navigator : null;

    // return this;

    return new Proxy(this, handler);
  }

  // get currentTime() {
  //   return this.context.currentTime;
  // }

  // get state() {
  //   return this.context.state;
  // }

  get destination() {
    return this.masterGain || this.context.destination;
  }
  // createAnalyser() {
  //   return this.context.createAnalyser();
  // }
  // createBufferSource() {
  //   return this.context.createBufferSource();
  // }

  // createGain() {
  //   return this.context.createGain();
  // }

  // decodeAudioData(buffer) {
  //   return this.context.decodeAudioData(buffer);
  // }

  close() {
    if (this.context && typeof this.context.close !== 'undefined') {
      // disconnect the old gain node
      if (this.masterGain) {
        this.masterGain.disconnect();
        this.masterGain = null;
      }

      this.context.close();
      this.context = null;
    }
  }
  get codecs() {
    return { ...this.codecs_ };
  }
  get volume() {
    return this.volume_;
  }

  get scratchBuffer() {
    return this.scratchBuffer_;
  }

  set volume(vol) {
    if (vol >= 0 && vol <= 1) {
      this.volume_ = vol;
      this.muted = false;
      this.masterGain.gain.setValueAtTime(vol, this.context.currentTime);
    }
  }

  mute(muted) {
    this.muted = muted;
    this.masterGain.gain.setValueAtTime(muted ? 0 : this.volume_, this.context.currentTime);
  }

  // resume() {
  //   this.context.resume();
  // }

  // suspend() {
  //   this.context.suspend();
  // }

  enableMobileAudio() {
    // Only run this on mobile devices if audio isn't already eanbled.
    const isMobile =
      detectIpad() ||
      /iPhone|iPad|iPod|Android|BlackBerry|BB10|Silk|Mobi|Chrome/i.test(this.navigator_ && this.navigator_.userAgent);

    if (this.mobileEnabled_ || !this.context || !isMobile) {
      return;
    }

    this.mobileEnabled_ = false;
    this.mobileAutoEnable = false;
    // Some mobile devices/platforms have distortion issues when opening/closing tabs and/or web views.
    // Bugs in the browser (especially Mobile Safari) can cause the sampleRate to change from 44100 to 48000.
    // By calling unload(), we create a new AudioContext with the correct sampleRate.
    if (!this.mobileUnloaded_ && this.context.sampleRate !== 44100) {
      this.mobileUnloaded_ = true;
      this.close();

      this.context = getAudioContext();

      // if get a new context need to replace the old master gain as it is connected to the old context
      this.masterGain = createMasterGain_(this.context, this.muted);
    }

    const scratchBuffer = this.context.createBuffer(1, 1, 22050);

    // Scratch buffer for enabling iOS to dispose of web audio buffers correctly, as per:
    // http://stackoverflow.com/questions/24119684
    if (navigator && /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
      this.scratchBuffer_ = scratchBuffer;
    }

    // Call this method on touch start to create and play a buffer,
    // then check if the audio actually played to determine if
    // audio has now been unlocked on iOS, Android, etc.
    const unlock = e => {
      e.preventDefault();

      // Create an empty buffer.
      const source = this.context.createBufferSource();
      source.buffer = scratchBuffer;
      source.connect(this.context.destination);

      // Play the empty buffer.
      source.start(0);

      // Calling resume() on a stack initiated by user gesture is what actually unlocks the
      // audio on Android Chrome >= 55.
      if (typeof this.context.resume === 'function') {
        this.context.resume();
      }

      // Setup a timeout to check that we are unlocked on the next event loop.
      source.onended = function() {
        source.disconnect(0);

        // Update the unlocked state and prevent this check from happening again.
        this.mobileEnabled_ = true;

        // Remove the touch start listener.
        document.removeEventListener('touchstart', unlock, true);
        document.removeEventListener('touchend', unlock, true);
        document.removeEventListener('click', unlock, true);
      };
    };

    // Setup a touch start listener to attempt an unlock in.
    document.addEventListener('touchstart', unlock, true);
    document.addEventListener('touchend', unlock, true);
    document.addEventListener('click', unlock, true);

    return this;
  }
}

// const AudioGraph = new Proxy(AudioGraph_, handler);

// export { AudioGraph };

// ===
// Private functions
// ===

function createMasterGain_(context, muted) {
  const masterGain = context.createGain();
  masterGain.gain.setValueAtTime(muted ? 0 : 1, context.currentTime);
  masterGain.connect(context.destination);

  return masterGain;
}

function getAudioContext() {
  // no-undef
  // eslint-disable-next-line new-cap
  return typeof AudioContext !== 'undefined' ? new AudioContext() : new webkitAudioContext();
}

function getCodecs() {
  let audioTest = null;
  const codecs = {};

  // Must wrap in a try/catch because IE11 in server mode throws an error.
  try {
    audioTest = typeof Audio !== 'undefined' ? new Audio() : null;
  } catch (err) {
    return codecs;
  }

  if (!audioTest || typeof audioTest.canPlayType !== 'function') {
    return codecs;
  }

  tests_().forEach(([key, mimeType]) => {
    if (!Array.isArray(mimeType)) {
      mimeType = [mimeType];
    }
    codecs[key] = mimeType.some(mt => !!audioTest.canPlayType(mt).replace(/^no$/, ''));
  });

  return codecs;
}

function tests_() {
  return [
    ['mp3', 'audio/mp3'],
    ['mpeg', 'audio/mpeg'],
    ['opus', 'audio/ogg; codecs="opus"'],
    ['ogg', 'audio/ogg; codecs="vorbis"'],
    ['oga', 'audio/ogg; codecs="vorbis"'],
    ['wav', 'audio/wav; codecs="1"'],
    ['aac', 'audio/aac;'],
    ['weba', 'audio/webm; codecs="vorbis"'],
    ['webm', 'audio/webm; codecs="vorbis"'],
    ['dolby', 'audio/mp4; codecs="ec-3"'],
    ['caf', ['audio/x-m4a;', 'audio/m4a;', 'audio/aac;']],
    ['mp4', ['audio/x-mp4;', 'audio/mp4;', 'audio/aac;']],
    ['flac', ['audio/x-flac;', 'audio/flac;']],
  ];
}

function detectIpad() {
  const ua = navigator.userAgent;
  if (ua.indexOf('iPad') > -1) {
    return true;
  }

  if (ua.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      // empty
    }
  }

  return false;
}

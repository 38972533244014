export class SpeechDetector {
  constructor({ audioContext, source, smoothing = 0.1, interval = 50, threshold = -50, history = 10 } = {}) {
    this.audioContext = audioContext;
    this.source = source;
    this.interval = interval;
    this.threshold = threshold;
    this.history = history;

    const analyser = this.audioContext.createAnalyser();
    analyser.fftSize = 512;
    analyser.smoothingTimeConstant = smoothing;

    this.fftBins = new Float32Array(analyser.frequencyBinCount);
    this.analyser = analyser;

    this.source.connect(analyser);

    this.running = false;
    this.disposed = false;
  }

  setThreshold(nv) {
    this.threshold = nv;
  }

  setInterval(nv) {
    this.interval = nv;
  }

  stop() {
    this.running = false;
  }

  dispose() {
    if (!this.disposed) {
      this.disposed = true;
      this.running = false;

      this.analyser.disconnect();
      this.source.disconnect();
      this.analyser = null;
    }
  }

  async *analyse() {
    if (this.running) {
      throw new Error('Analyser already running');
    }

    this.running = true;

    const speakingHistory = new Array(this.history).fill(0);
    let speaking = false;

    while (this.running) {
      await delay(this.interval);

      if (this.analyser) {
        const currentVolume = getMaxVolume(this.analyser, this.fftBins);

        let history = 0;

        if (currentVolume > this.threshold && !speaking) {
          for (let i = speakingHistory.length - 3; i < speakingHistory.length; i++) {
            history += speakingHistory[i];
          }
          if (history >= 2) {
            speaking = true;
          }
        } else if (currentVolume < this.threshold && speaking) {
          for (let i = 0; i < speakingHistory.length; i++) {
            history += speakingHistory[i];
          }
          if (history == 0) {
            speaking = false;
          }
        }

        yield { currentVolume, threshold: this.threshold, speaking };
        speakingHistory.shift();
        speakingHistory.push(0 + (currentVolume > this.threshold));
      }
    }
  }
}

// ===
// Private functions
// ===

function getMaxVolume(analyser, fftBins) {
  let maxVolume = -Infinity;
  analyser.getFloatFrequencyData(fftBins);

  for (let i = 4, ii = fftBins.length; i < ii; i++) {
    if (fftBins[i] > maxVolume && fftBins[i] < 0) {
      maxVolume = fftBins[i];
    }
  }

  return maxVolume;
}

const delay = t => new Promise(resolve => setTimeout(resolve, t));

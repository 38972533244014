import Vuex from 'vuex';

const { mapState, mapGetters, mapMutations, mapActions } = Vuex;
export const authComputed = {
  ...mapState('auth', {
    currentUser: state => state.currentUser,
  }),
  ...mapGetters('auth', ['loggedIn', 'myRole', 'can']),
};

export const authMethods = {
  ...mapActions('auth', ['logIn', 'logOut', 'requestResetCode', 'loginWithCode']),
  ...mapMutations('auth', { updateUserName: 'UPDATE_NAME' }),
};

export const rbacComputed = {
  ...mapGetters('rbac', ['rbacCan']),
};

export const baseComputed = mapState({ audioGraph: state => state.audioGraph });

export const dataComputed = {
  ...mapState('data', {
    count: state => state.count,
    status: state => state.status,
  }),
};

export const dataMethods = mapActions('data', ['save']);

export const resultsComputed = {
  ...mapState('results', {
    currentResults: state => state.currentResults,
  }),
};

export const resultsMethods = mapActions('results', ['createResults', 'clearResults', 'setState', 'pushItem']);

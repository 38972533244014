import ky from 'ky';

export default {
  load(url, name = 'default') {
    return import(/* webpackChunkName: "[request]" */ `~/${url}`).then(async m => {
      const component = m[name];
      if (!component.name) {
        component.name = baseName(url);
      }

      return Object.create(component);
    });
  },

  loadRaw(url) {
    return import(`~/${url}`).then(r => {
      return r;
    });
  },

  loadUrl(url) {
    return ky.get(url);
  },
};

// ===
// Private functions
// ===

function baseName(str) {
  let base = str.substring(str.lastIndexOf('/') + 1);
  if (base.lastIndexOf('.') != -1) {
    base = base.substring(0, base.lastIndexOf('.'));
  }
  return base;
}

import { objectID } from '@ounce/onc';
import ky from 'ky';
import { setupDb } from './data-db.js';

const responseTimeout_ = 3000;
const rcInitialDelay_ = 3000;

let db;

export default {
  // // This is automatically run in `src/state/store.js` when the app
  // // starts, along with any other actions named `init` in other modules.
  async init({ state, dispatch, commit }) {
    db = await setupDb();
    await dispatch('setStatusUp');
  },

  setStatusUp({ state, dispatch, commit }) {
    commit('SET_STATUS', 'up');
    dispatch('uploadNow');
  },

  setStatusDown({ state, dispatch, commit }) {
    commit('SET_STATUS', 'down');
  },

  async save({ commit, state, dispatch }, { token, value }) {
    const item = {
      id: objectID().toString(),
      token,
      createDate: new Date(),
      value,
    };

    await db.set(item.id, item);

    // update counter and trigger an upload
    commit('INCREMENT_COUNT', 1);
    dispatch('uploadNow');

    return item.id;
  },

  async uploadNow({ state, commit, dispatch }) {
    // if we are already uploading or know we cant, do nothing
    if (uploading_ || state.status !== 'up') {
      return;
    }
    // note we are uploading and get lsit of items
    uploading_ = true;

    try {
      const keys = await db.keys();
      commit('SET_COUNT', keys.length);

      if (keys.length > 0) {
        await processKeys(keys, db, () => commit('INCREMENT_COUNT', -1));
      }
      uploading_ = false;

      // if there are any more, try and upload them
      if (state.count > 0) {
        dispatch('uploadNow');
      }
    } catch (error) {
      // if we get here an upload failed
      // so we will go into the waiting state and retry
      uploading_ = false;
      console.dir(error.toString());

      dispatch('setWaitingAndRetry', { delay: rcInitialDelay_ });
    }
  },

  setWaitingAndRetry({ commit, dispatch }, { delay }) {
    commit('SET_STATUS', 'waiting');

    setTimeout(() => {
      dispatch('checkComms', { delay });
    }, delay);
  },

  async checkComms({ commit, dispatch }, { delay }) {
    commit('SET_STATUS', 'checking');
    try {
      await ky.head(testUrl_());
      dispatch('setStatusUp');
    } catch (error) {
      commit('SET_STATUS', 'waiting');
      // 1.5 * current delay capped at 1hour
      delay = Math.min(Math.ceil(delay * 1.5), 3600000);
      setTimeout(() => {
        dispatch('checkComms', { delay });
      }, delay);
    }
  },
};

// ===
// Private functions
// ===

let uploading_;

const testUrl_ = () => {
  return `/favicon.ico?_=${Math.floor(Math.random() * 1000000000)}`;
};

async function processKey(key, db) {
  const data = await db.get(key);
  if (!data.value) {
    data.value = {};
  }
  const url = data.value.uploadurl || '/api/data/';
  const method = data.value.method || 'post';

  return ky(url, {
    timeout: responseTimeout_,
    method,
    json: data,
  });
}

function processKeys(keys, db, onProcessed) {
  if (!keys || keys.length === 0) {
    keys = [];
  }

  return keys.reduce(async (p, v) => {
    await p;
    await processKey(v, db);
    await db.delete(v);
    await onProcessed();
  }, Promise.resolve());
}

import api from '@state/api.js';
import timestring from 'timestring';

const SETTINGSKEY = 'qw-settings';

export default {
  namespaced: true,
  state: {
    micThresholdLevel: 10,
    wordLookup: [],
    blockCount: 0,
    blockTrialIndex: 0,
    sessionTrialIndex: 0,
    inTimeResponses: 0,
    responseTimeMean: { cma: 0, n: 0 }, // cumulative moving average CMAn+1 = CMAn +((Xn+1 - CMAn) / n + 1)
    wordsets: [],
    sessionState: 'initial',
    sessionInfo: {
      namingSpeed: 0,
      wordset: {},
      wordsetDifficulty: 0,
      runs: 1,
      blocks: 1,
      type: 'ordinary',
      recommendedSpeed: 0,
    },
    userInfo: {
      namingSpeed: 0,
      count: 0,
      wordsetDifficulties: [],
      inTimeResponsePercent: null,
    },
    userref: '',
    projectId: '',
  },
  mutations: {
    initState(state, payload) {
      Object.entries(payload).forEach(([key, value]) => (state[key] = value));
    },
    resetResponseTimeMean(state) {
      state.responseTimeMean.cma = 0;
      state.responseTimeMean.n = 0;
    },
    responseTimeMeanNext(state, newValue) {
      const cman = state.responseTimeMean.cma;
      state.responseTimeMean.n += 1;
      state.responseTimeMean.cma = cman + (newValue - cman) / state.responseTimeMean.n;
    },
    SET_IDS(state, { userref, projectId }) {
      (state.userref = userref), (state.projectId = projectId);
    },
    SET_WORDSETS(state, { wordsets }) {
      state.wordsets = wordsets;
    },

    setSessionState(state, newValue) {
      state.sessionState = newValue;
    },
    setMicThresholdLevel(state, newValue) {
      state.micThresholdLevel = newValue;

      // note: this needs altering if we ever support more than the micThresholdLevel setting
      saveState(SETTINGSKEY, { micThresholdLevel: newValue });
    },
    setWordLookup(state, newValue) {
      state.wordLookup = newValue;
    },
    setNumberBlocks(state, newValue) {
      state.numberBlocks = newValue;
    },
    setBlockCount(state, newValue) {
      state.blockCount = newValue;
    },

    incrementBlockTrialIndex(state, incr = 1) {
      state.blockTrialIndex += incr;
    },
    resetBlockTrialIndex(state) {
      state.blockTrialIndex = 0;
    },
    setSessionTrialIndex(state, newValue) {
      state.sessionTrialIndex = newValue;
    },

    setInTimeResponses(state, newValue) {
      state.inTimeResponses = newValue;
    },

    // session info
    setSessionInfo(state, newValue) {
      state.sessionInfo = newValue;
    },

    setNamingSpeed(state, newValue) {
      state.sessionInfo.namingSpeed = newValue;
    },

    // user's persistent info
    setUserInfo(state, newValue) {
      state.userInfo = newValue;
    },

    incrementSessionCount(state) {
      state.userInfo.count += 1;
    },
    setUserNamingSpeed(state, newValue) {
      if (typeof newValue === 'string') {
        newValue = getDuration(newValue, 's');
      }
      const speed = state.userInfo.namingSpeed;
      state.userInfo.lastSpeed = {
        speed,
        type: state.sessionInfo.type,
        wordsetDifficulty: state.sessionInfo.wordsetDifficulty,
      };
      state.userInfo.namingSpeed = newValue;
    },
    setUserInTimeResponsePercent(state, newValue) {
      state.userInfo.inTimeResponsePercent = newValue;
    },
    setUserWordsetDifficulties(state, newValue) {
      state.userInfo.wordsetDifficulties = newValue;
    },
  },
  getters: {
    wordsetDifficulties(state) {
      return state.userInfo.wordsetDifficulties.slice();
    },
    userref(state) {
      return state.userref;
    },
    blockSize(state, getters) {
      return getters.sessionSize / state.sessionInfo.blocks;
    },
    sessionSize(state) {
      const {
        wordset: { entries },
        runs,
      } = state.sessionInfo;
      return entries.length * runs;
    },
  },
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ commit }) {
      const settings = getSavedState(SETTINGSKEY) ?? { micThresholdLevel: 10 };
      commit('initState', settings);
    },

    async saveUserInfo({ state, commit }, { wordsetDifficulties } = {}) {
      const userref = state.userref;
      const projectId = state.projectId;

      if (wordsetDifficulties) {
        commit('setUserWordsetDifficulties', wordsetDifficulties);
      }
      await api.put(`/api/participant/${userref}/${projectId}/info`, { json: state.userInfo });
    },

    async fetchSelectedWordset({ state, commit }, { wordsetId }) {
      const { wordset } = await api.get(`/api/session/${wordsetId}`).json();
      return wordset;
    },

    async fetchSession({ state, commit, rootState }, { userref, projectId }) {
      const { wordsets, userInfo } = await api.get(`/api/session/${userref}/${projectId}`).json();

      commit('SET_IDS', { userref, projectId });
      commit('SET_WORDSETS', { wordsets });

      commit('setUserInfo', Object.assign({}, defaultUserInfo_, userInfo));
    },

    async setSessionType({ state, commit, rootState }, sessionType) {
      commit('SET_SESSION_TYPE', sessionType);
    },
  },
};

// ===
// Private helpers
// ===

const defaultUserInfo_ = {
  namingSpeed: 0,
  count: 0,
  wordsetDifficulties: [],
  inTimeResponsePercent: null,
  lastSpeed: null,
};

// function saveSessionState(state) {
//   const ss = {
//     userInfo: state.userInfo,
//   };
//   saveState(state.sessionKey, ss);
// }

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

function getDuration(value, units) {
  value = String(value);
  const groups = value
    .toLowerCase()
    .replace(/[^.\w+-]+/g, '')
    .match(/[-+]?[0-9.]+[a-z]+/g);
  if (!groups) {
    value = `${value}${units}`;
  }

  return timestring(value, 'ms');
}

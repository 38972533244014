import { shuffle } from '@ounce/onc';
import router from '@router/index.js';
import store from '@state/store.js';
import { format, parseISO } from 'date-fns';
import seedrandom from 'seedrandom';
import Vue from 'vue';
import app from './app.js';
import vueerr from './components/error.js';
import { vNobounce, vScroll, vVisible } from './components/plugins.js';
import loader from './loader.js';
import registerSW from './register.js';
import roles from './roles.js';
import VueMaterialAdapter from './vma.js';

export default function main() {
  // we want to suppress active/hover on iPad as it is persistent across page loads in a web app.
  const isIpad = detectIpad();
  if (isIpad) {
    // using classList is safe here as iOS is known to support it.
    document.querySelector('html').classList.add('ipad');
  } // isIpad

  captureContextMenu();

  checkIOS9();

  Vue.use(vueerr);
  Vue.use(VueMaterialAdapter);
  Vue.filter('isoDate', value => {
    return value ? format(parseISO(value), 'yyyy-MM-dd HH:mm') : 'n/a';
  });
  Vue.component('navbar', () => loader.load('components/navbar.js'));

  const rng = seedrandom();
  shuffle.setRng(rng);

  //
  store.dispatch('rbac/perms', { roles });
  //
  Vue.use(vVisible);
  Vue.use(vNobounce);
  Vue.use(vScroll);

  new Vue({
    router,
    store,
    render: h => h(app),
    computed: {
      noRipple() {
        return true;
      },
    },
  }).$mount('#app');
}

// developers: the next line registers the service worker
// it can be commented out for debug or otherwise if necessary
// but remember to purge any service workers already installed
// from the browsers developer tools options.

registerSW(store);
main();

// ===
// Private functions
// ===

function captureContextMenu() {
  window.addEventListener('contextmenu', e => {
    e.returnValue = false;
    e.preventDefault();
  });
}

function checkIOS9() {
  if (/iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream) {
    const iosVersion = navigator.userAgent.match(/OS (\d+)_(\d+)/i);
    if (iosVersion[1] >= 9) {
      document
        .querySelector('meta[name=viewport]')
        .setAttribute('content', 'initial-scale=1.0001, minimum-scale=1.0001, maximum-scale=1.0001, user-scalable=no');
    }
  }
}

function detectIpad() {
  const ua = navigator.userAgent;
  if (ua.indexOf('iPad') > -1) {
    return true;
  }

  if (ua.indexOf('Macintosh') > -1) {
    try {
      document.createEvent('TouchEvent');
      return true;
    } catch (e) {
      // empty
    }
  }

  return false;
}

import Vuex from 'vuex';

const { mapState, mapActions } = Vuex;

export const projectComputed = {
  ...mapState('project', {
    currentProject: state => state.currentProject,
    lastProjectId: state => state.lastProjectId,
  }),
};

export const projectMethods = mapActions('project', ['fetchProject']);

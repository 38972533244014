import { RandomString } from '@ounce/onc';

export default {
  namespaced: true,
  state: {
    currentResults: getSavedState('results.currentResults'),
  },
  mutations: {
    SET_CURRENT_RESULTS(state, newValue) {
      state.currentResults = newValue;
      saveState('results.currentResults', newValue);
    },
    SET_STATE(state, newValue) {
      state.currentResults.state = newValue;

      saveState('results.currentResults', state.currentResults);
    },

    PUSH_TO_ITEMS(state, newValue) {
      state.currentResults.items.push(newValue);
      saveState('results.currentResults', state.currentResults);
    },
  },
  getters: {},
  actions: {
    // // This is automatically run in `src/state/store.js` when the app
    // // starts, along with any other actions named `init` in other modules.
    // init({ state, dispatch }) {
    // },

    setState({ state, commit }, newValue) {
      commit('SET_STATE', newValue);
    },
    pushItem({ state, commit }, item) {
      commit('PUSH_TO_ITEMS', item);
    },

    createResults(
      { state, commit, getters },
      { userref, sui, taskId, appVersion, sessionInfo, projectId, state: taskState },
    ) {
      // todo: handle existing results

      const session = {
        userref,
        sui,
        taskId,
        projectId,
        appVersion,
        userAgent: navigator.userAgent,
        state: taskState,
        created: new Date(),
        sessionId: RandomString.string(),
        sessionInfo,
        items: [],
      };

      commit('SET_CURRENT_RESULTS', session);
      return session;
    },
    clearResults({ state, commit }) {
      commit('SET_CURRENT_RESULTS', null);
    },
  },
};

// ===
// Private functions
// ===

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}

import Vuex from 'vuex';

const { mapState, mapGetters, mapActions, mapMutations } = Vuex;
const name_ = 'session';

export const sessionComputed = {
  ...mapState(name_, {
    wordsets: state => state.wordsets,

    wordset: state => state.sessionInfo.wordset,
    selectedWordset: state => state.sessionInfo.wordset,
    micThresholdLevel: state => state.micThresholdLevel,
    sessionInfo: state => state.sessionInfo,
    numberBlocks: state => state.sessionInfo.blocks,
    namingSpeed: state => state.sessionInfo.namingSpeed,
    sessionType: state => state.sessionInfo.type,
    wordsetDifficulty: state => state.sessionInfo.wordsetDifficulty,
    lastSpeed: state => ({ ...state.userInfo.lastSpeed }),
    userSessionCount: state => state.userInfo.count,
    userNamingSpeed: state => state.userInfo.namingSpeed,
    userInTimeResponsePercent: state => state.userInfo.inTimeResponsePercent,
    sessionState: state => state.sessionState,
    blockCount: state => state.blockCount,
    blockTrialIndex: state => state.blockTrialIndex,
    sessionTrialIndex: state => state.sessionTrialIndex,
    inTimeResponses: state => state.inTimeResponses,
    responseTimeMean: state => state.responseTimeMean.cma,
    wordLookup: state => state.wordLookup,
    sessionUserref: state => state.userref,
    sessionProjectId: state => state.projectId,
  }),
  ...mapGetters(name_, ['blockSize', 'sessionSize', 'wordsetDifficulties']),
};

export const sessionMethods = {
  ...mapActions(name_, ['fetchSession', 'fetchSelectedWordset', 'saveUserInfo']),
  ...mapMutations(name_, [
    'setMicThresholdLevel',
    'setWordLookup',
    'setNumberBlocks',
    'setSessionState',
    'setBlockCount',
    'setBlockSize',
    'incrementBlockTrialIndex',
    'resetBlockTrialIndex',
    'setSessionTrialIndex',
    'setSessionSize',
    'setInTimeResponses',
    'setSessionInfo',
    'setUserNamingSpeed',
    'setUserInTimeResponsePercent',
    'incrementSessionCount',
    'responseTimeMeanNext',
    'resetResponseTimeMean',
  ]),
};

import api from '@state/api.js';
import timestring from 'timestring';

export default {
  namespaced: true,
  state: {
    currentProject: null,
    lastProjectId: null,
  },
  mutations: {
    SET_CURRENT_PROJECT(state, newValue) {
      state.currentProject = newValue;
    },
    SET_LAST_PROJECT_ID(state, newValue) {
      state.lastProjectId = newValue;
    },
  },
  getters: {},
  actions: {
    // This is automatically run in `src/state/store.js` when the app
    // starts, along with any other actions named `init` in other modules.
    init({ state, dispatch, rootGetters }) {
      const userId = rootGetters['auth/userId'];
      const { id } = getSavedState(`lastProject-${userId}`) || { id: null };
      state.lastProjectId = id;
    },

    async fetchProject({ state, commit, rootGetters }, { projectId }) {
      const { project } = await api.get(`/api/project/${projectId}`).json();
      // const project = { ...defaultAuction_, ...project };
      fixupDurations(project);
      commit('SET_CURRENT_PROJECT', project);
      commit('SET_LAST_PROJECT_ID', projectId);

      const userId = rootGetters['auth/userId'];

      saveState(`lastProject-${userId}`, { id: projectId });
      return project;
    },
  },
};

// ===
// Private helpers
// ===

const fields = [
  'adaptation.decreaseDeltaLTE3',
  'adaptation.decreaseDeltaGT3',
  'adaptation.increaseDeltaLT3',
  'adaptation.increaseDeltaGTE3',
  'adaptation.minNamingSpeed',
  'adaptation.maxNamingSpeed',
  'session.namingSpeed',
  ['trial.fixationTime', 'ms'],
  ['trial.repetitionInterval', 'ms'],
  ['trial.feedbackTime', 'ms'],
];
function fixupDurations(project) {
  fields.forEach(item => {
    let units = 's';
    if (Array.isArray(item)) {
      [item, units] = item;
    }
    item.split(/[.[\]]+/).reduce((prev, key, index, array) => {
      if (array.length === index + 1) {
        prev[key] = getDuration(prev[key], units);
      }

      return prev[key];
    }, project);
  });
}

function getDuration(value, units) {
  value = String(value);
  const groups = value
    .toLowerCase()
    .replace(/[^.\w+-]+/g, '')
    .match(/[-+]?[0-9.]+[a-z]+/g);
  if (!groups) {
    value = `${value}${units}`;
  }

  return timestring(value, 'ms');
}

function getSavedState(key) {
  return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
  window.localStorage.setItem(key, JSON.stringify(state));
}
